/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './editor.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:focus {
  outline: none !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

:root {
  --hue: 227deg; /* Adjusted hue */
  --background: 0deg 0% 100%; /* White in HSL */
  --primary: 227deg 47% 16%; /* #161E3A in HSL */
}

@property --deg {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: true;
}

@keyframes rotate {
  to {
    --deg: 360deg;
  }
}

.animated-border-button {
  border: 3px solid transparent;
  background: linear-gradient(hsl(var(--background)), hsl(var(--background)))
      padding-box,
    conic-gradient(
        from var(--deg),
        hsla(var(--primary) / 0%),
        hsl(var(--primary)) 100%
      )
      border-box;
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
